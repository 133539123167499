<template>
  <div>
    <v-row class="text-center align-start">
      <!-- Video player -->
      <v-col class="col-md-7 col-12">
        <v-img
          :src="require('@/assets/video_placeholder.jpg')"
          class="grey lighten-5"
          height="400px;"
          width="100%"
          v-if="!activeVideo"
          @click="selectFirstVideo"
        ></v-img>
        <!-- <VideoPlayer :activeVideo="activeVideo" :key="activeVideo" /> -->
        <VideoPlayer
          v-if="activeVideo && activeVideo.downloadVideoUrl"
          :activeVideo="activeVideo"
          :autoplaySetting="shouldVideoAutoplay"
          :timeToPlay="timeToPlay"
          :key="selectedVideo"
        />
      </v-col>
      <!-- Playlist with all videos -->
      <v-col class="col-md-5 col-12" v-if="videos">
        <v-card class="mx-auto text-left" dark tile>
          <v-list class="overflow-y-auto" max-height="450" shaped>
            <v-subheader>VIDEOS</v-subheader>
            <v-list-item-group
              v-model="selectedVideo"
              color="white"
              v-if="videos"
            >
              <v-list-item
                v-for="(item, i) in videos"
                :key="i"
                @click="chooseVideo(item)"
              >
                <v-list-item-icon>
                  <v-icon v-if="!item.src_video">mdi-lock-outline</v-icon>
                  <v-icon v-else>mdi-play-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    <v-chip
                      class="ma-0"
                      color="primary"
                      small
                      label
                      v-if="item.isTrailer"
                    >
                      TRAILER
                    </v-chip>
                    <!-- {{ item.videoPositionIndex }} |  -->
                    {{ item.title }}
                  </v-list-item-title>
                  <!-- <v-list-item-subtitle
                    >{{ item.created_at }} ||
                    {{ item.datetime }}</v-list-item-subtitle
                  > -->
                  <!-- <v-list-item-subtitle>{{ new Date(item.datetime) | dateFormat('D MMM YYYY') }}</v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>

        <!-- <p class="white--text">{{ videos }}</p> -->
      </v-col>
    </v-row>

    <v-row class="align-start" v-if="!user">
      <v-col class="col-md-8 col-12">
        <p class="body-1" v-if="activeVideo">{{ activeVideo.description }}</p>
      </v-col>
      <v-col class="col-md-4 col-12"></v-col>
    </v-row>

    <v-row v-if="user">
      <v-tabs v-if="activeVideo" :key="selectedVideo">
        <v-tab>DESCRIZIONE</v-tab>
        <v-tab>COMMENTI</v-tab>
        <v-tab v-if="activeVideo.highlights.length !== 0"
          >IN QUESTO VIDEO</v-tab
        >
        <v-tab-item>
          <v-card height="500" flat>
            <v-card-text>
              <p class="body-1" v-if="activeVideo">
                {{ activeVideo.description }}
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card height="500" flat>
            <v-card-text>
              <CommentVideo :activeVideo="activeVideo" v-if="activeVideo" />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="activeVideo.highlights.length !== 0">
          <v-card height="500" flat>
            <v-card-text>
              <QuestionsVideo
                :activeVideo="activeVideo"
                v-if="activeVideo"
                @changePlayTime="setTimeToPlay"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-row>

    <!-- <v-row>
      <v-col class="col-md-4 col-12 white--text">
        <p>Video selected</p>
        <h1>{{ this.selectedVideo }}</h1>
        <p>Video Active</p>
        <h1>{{ this.activeVideo }}</h1>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VideoPlayerPlaylist",

  components: {
    VideoPlayer: () => import("@/components/webapp/VideoPlayer"),
    CommentVideo: () => import("@/components/webapp/CommentVideo"),
    QuestionsVideo: () => import("@/components/webapp/QuestionsVideo"),
  },

  data: () => ({
    selectedVideo: 0,
    activeVideo: null,
    timeToPlay: 0,

    shouldVideoAutoplay: false,
  }),

  props: {
    videos: {
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

  methods: {
    chooseVideo(item) {
      this.shouldVideoAutoplay = true;

      this.activeVideo = null;
      this.timeToPlay = 0;
      this.activeVideo = item;
      // console.log(item);
    },
    selectFirstVideo() {
      this.selectedVideo = 0;
      this.activeVideo = this.videos[0];
    },
    playFunc(video) {
      console.log("video has played!");
      console.log(video);
      console.log(`current time is: ${video.target.currentTime}`);
    },
    pauseFunc(video) {
      console.log("video has paused!");
      console.log(video);
      console.log(`current time is: ${video.target.currentTime}`);
    },
    endedFunc() {
      console.log("video has ended. maybe ask rating?");
    },
    setTimeToPlay(time) {
      this.timeToPlay = time;
    },
  },

  mounted() {
    if (this.$route.query.video) {
      const result = this.videos.find(
        ({ id }) => id === this.$route.query.video
      );
      this.activeVideo = result;
      const index = this.videos.findIndex(
        (x) => x.id === this.$route.query.video
      );
      this.selectedVideo = index;

      if (!result) {
        this.activeVideo = this.videos[0];
        this.selectedVideo = 0;
        // probably we could also change the url in a followup improvement
      }
    } else {
      // console.log("id is --> " + this.videos[0].id);
      // console.log("activeVideo before -> " + this.activeVideo);
      this.activeVideo = this.videos[0];
      // console.log("activeVideo after -> " + this.activeVideo);
      // console.log("this.videos after -> " + this.videos);
    }
  },
};
</script>

<style scoped>
.example-player {
  height: 450px;
}
</style>